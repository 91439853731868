

.productSelectWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: 700px;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.productSelect {
  display: flex;
  width: 100%;
  max-width: 1440px;
  position: relative;
  margin-right: 1rem;
}

.photosSelection {
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: .5rem;
  display: flex;
  flex-direction: column;
}

.photoInPhotosSelectionWrapper {
  overflow: hidden;
  margin-bottom: .75rem;
  border: 1px solid #3B848C;
  border-radius: 2px;
  width: 40px;
  height: 52px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.photoInPhotosSelection {
  width: 100%;
}

.hoveredSelectionPhoto {
  border-color: #e77600;
  box-shadow: 0 0 5px 1px rgb(228, 121, 0.5);
}

.productSelectMainPhotoWrapper {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  max-width: 48vw;
  // background: cyan;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productSelectMainPhoto {
  // max-height: 100%;
  // height: 100%;
  // height: auto;
  // height: auto;
  // max-width: 100%;
  max-height: 100%;
  max-width: 100%;
  margin-top: .35rem;
}

.productSelectDetailsWrapper {
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}

.productSelectDetails {
  width: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .5rem;
}

.productSelectAddToCartWrapper {
  width: 100%;
  max-width: 240px;
}

.productSelectAddToCart {
  border: 1px solid #3B848C;
  border-radius: 5px;
  padding: 1rem;
}

.productSelectDetailsTitle {
  font-size: 1.5rem;
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
  line-height: 1.25;
  color: #3d3b3b;
}

.produceSelectPriceWrapper {
  display: flex;
  border-top: 2px solid #f8f6f4;
  margin-top: 1rem;
  padding-top: 1rem;
}

.produceSelectPrice {
  font-size: 1.25rem;
  color: #B12705;
}

.productSelectSelectWrapper {
  margin-top: 1rem;
}

.productSelectSelect {
  margin-top: .5rem;
}

.productSelectDetails {
  ul {
    list-style: inherit;
    margin-left: 9px;
    // list-style-position: inside;
  }
}

.productSelectOption {
  // word-wrap: break-word;
  // display: block;
}

.productSelectButton {
  width: 100%;
  border-radius: 2rem !important;
}

.numberSelect {
  margin-bottom: 1rem !important;
}

.productSelectAddToCartPrice {
  margin-bottom: 1rem;
}

.productSelectButtonWrapper {
  margin-bottom: 1rem;
}

.produceSelectCountry {
  width: 100%;
  height: 100%;
  margin-right: 1rem;
  cursor: pointer;
}

.produceSelectCountry img {
  width: 100px;
  display: block;
}

.produceSelectCountrySelected {
  border: 1px solid #ef261c;
  height: 100%;
}

.produceSelectCountryWrapper {
  display: inline-flex;
}

.productSelectDetailsTitleMobileWrapper {
  margin: 1rem;
}

.productSelectDetailsTitleMobile {
  color: #565959;
  font-weight: 700;
  font-size: 1.1rem;
}

.mobileProduceSelectCountries {
  margin: 1rem;
}

.mobilePhotoInPhotosSelectionWrapper {
  display: flex;
  justify-content: center;
  background: #f8f8f8;
  width: 100%;
  overflow: hidden;
}

.mobilePhotoInPhotosSelection {
  width: auto;
  height: 290px;
}

.spacer {
  width: 100%;
  height: .35rem;
  background-color: #F3F3F3;
  margin-bottom: 1rem;
}

.mobileLabel {
  font-size: 1rem;
  line-height: 1.25;
  color: #0F1111;
  font-weight: 400;
  margin: 1rem;
}

.mobileSelectedLabel {
  font-weight: bold;
}

.cleanedProductsWrapper {
  display: flex;
  margin: 1rem;
  flex-wrap: wrap;
}

.cleanedProduct {
  border: 1px solid rgb(162, 166, 172);
  padding: .75rem;
  border-radius: 3px;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  margin-right: .5rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.selectedCleanedProduct {
  background: black;
  color: white;
  border: 0;
}

.mobileProductSelectAddToCartWrapper {
  margin: 1rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.mobileProductWrapper {
  margin: 1rem;
}

.cleanedProductsWrapperDesktop {
  margin-top: 1rem;
  display: flex;
}

@media (max-width: 950px) {
  .mobileProductSelectWrapper {
    display: block;
  }

  .productSelectWrapper {
    display: none;
  }
}

@media (min-width: 951px) {
  .mobileProductSelectWrapper {
    display: none;
  }
}
