.header {
  width: 100%;
  height: 100%;
  border-bottom: 5px solid #3B848C;
  background-size: cover;
  background-color: #364559;
  display: flex;
}

.shoppingCartNavButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 3rem;
  color: white;
}

.shoppingCartNavButton {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.yourCart {
  font-weight: bold;
  margin-left: .5rem;
}

.logo {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3rem;
}

.logoText {
  font-size: 4.5rem;
  font-weight: bold;
  font-family: 'Archivo Black', sans-serif;
  color: #0D0D0D;
  text-shadow: -2px -2px 0 #FFFFFF, 2px -2px 0 #FFFFFF, -2px 2px 0 #FFFFFF, 2px 2px 0 #FFFFFF;
  cursor: pointer;
}

.tagline {
  color: #FFFFFF;
  font-size: 1rem;
  padding-bottom: .5rem;
}

@media (max-width: 991px) {
  .logo {
    display: flex;
    margin-left: .5rem;
  }
  .shoppingCartNavButtonWrapper {
    margin-right: .5rem;
  }
}

@media (max-width: 600px) {
  .header {
    display: flex;
    flex-direction: column;
  }
  .logo {
    display: flex;
    margin-left: 0rem;
  }
  .shoppingCartNavButtonWrapper {
    margin-right: 0rem;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .logoText {
    font-size: 2.5rem
  }
}
