.contentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.information-h1 {
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-family: 'Archivo Black', sans-serif;
}

.information-h2 {
  max-width: 900px;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.4;
  font-family: 'Archivo Black', sans-serif;
}

.information-content {
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.productShopWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 2rem;
}

.productShopProducts {
  display: flex;
  justify-content: center;
  max-width: 1275px;
  width: 100%;
  flex-wrap: wrap;
}

.productShopProduct {
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
}

.productShopProductImageWrapper {
  position: relative;
  padding-top: 100%;
  background: #f8f8f8;
}

.productShopProductImage {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.productShopDisclaimer {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  margin-bottom: 8px;
  font-style: italic;
}

.productShopProductTitle {
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: 1rem;
}

.productShopProductPrice {
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.productShopProductText {
  padding: .75rem;
}

.productShopInformationWrapper {
  width: 100%;
}


@media (max-width: 500px) {
  .productShopProduct {
    flex: 1 0 100% !important;
    max-width: calc(100% - 10px)  !important;
  }
}
