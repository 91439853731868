.footerWrapper {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: #364559;
}

.footer {
  padding: 1rem;
  text-align: center;
  color: #FFFFFF;
  font-size: .9rem;
}

.footer a {
  color: #3B848C;
}
